import { ProfileEntry } from '@app/auth/ngxs/auth.model';
import { type } from '@app/utils';

const ACTION_PREFIX = '[NGXS Auth]';

export const ActionTypes = {
    INITIALIZE_PROFILE_CHANGE: type(`${ ACTION_PREFIX } Initialize Profile Change`),
    UPDATE_AVAILABLE_PROFILES: type(`${ ACTION_PREFIX } Update Available Profiles`),
    CHANGE_PROFILE: type(`${ ACTION_PREFIX } Change Profile`),
    REDIRECT_AFTER_PROFILE_CHANGE: type(`${ ACTION_PREFIX } Redirect After Profile Change`),

    CLEAR_AUTH_STATE: type(`${ ACTION_PREFIX } CLear Auth State`),
};

export class InitializeProfileChange {
    static readonly type = ActionTypes.INITIALIZE_PROFILE_CHANGE;
    constructor(public profiles?: ProfileEntry[], public next?: string, public isSamlRequest?: string) {}
}

export class UpdateAvailableProfiles {
    static readonly type = ActionTypes.UPDATE_AVAILABLE_PROFILES;
    constructor(public profiles: ProfileEntry[]) {}
}

export class ClearAuthState {
    static readonly type = ActionTypes.CLEAR_AUTH_STATE;
}

export class RedirectAfterProfileChange {
    static readonly type = ActionTypes.REDIRECT_AFTER_PROFILE_CHANGE;
}
export class ChangeProfile {
    static readonly type = ActionTypes.CHANGE_PROFILE;
    constructor(public id: string) {}
}

export type Actions =
    | InitializeProfileChange
    | UpdateAvailableProfiles
    | ChangeProfile
    | ClearAuthState
    | RedirectAfterProfileChange
    ;
